<template>
    <div>

        <!-- Form Modal -->
        <b-modal id="modal-season-form" ref="myModalDriver" centered no-close-on-backdrop no-close-on-esc size="lg"
            @ok="submitDriver">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5 v-if="clerk.id === null">Add Clerk</h5>
                <h5 v-if="clerk.id !== null">Edit Clerk</h5>

                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button size="md" variant="primary" @click="ok()" :disabled="saving">
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button size="md" @click="cancel()" variant="outline-secondary">
                    Cancel
                </b-button>
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{ }">
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreateFleet">

                        <b-row>
                            <b-col cols="4">
                                <b-form-group labe-for="code" label="Code">
                                    <validation-provider #default="{ errors }" name="code" rules="required">
                                        <b-form-input id="code" name="code" v-model="clerk.code"
                                            :state="errors.length > 0 ? false : null" size="md" value="" type="text" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.code">{{
                                            serverErrors.code[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group labe-for="firstName" label="First Name">
                                    <validation-provider #default="{ errors }" name="firstName" rules="required">
                                        <b-form-input id="firstName" name="firstName" v-model="clerk.firstName"
                                            :state="errors.length > 0 ? false : null" size="md" value=""
                                            v-on:keypress="isLetter($event)" type="text"
                                            oninvalid="setCustomValidity('Please enter on alphabets only. ')" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.firstName">{{
                                            serverErrors.firstName[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group labe-for="middleName" label="Middle Name">
                                    <validation-provider #default="{ errors }" name="middleName">
                                        <b-form-input id="middleName" name="middleName" v-model="clerk.middleName"
                                            :state="errors.length > 0 ? false : null" size="md" value=""
                                            v-on:keypress="isLetter($event)" type="text" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.middleName">{{
                                            serverErrors.middleName[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>


                            <b-col cols="4">
                                <b-form-group labe-for="lastName" label="Last Name">
                                    <validation-provider #default="{ errors }" name="lastName" rules="required">
                                        <b-form-input id="lastName" name="lastName" v-model="clerk.lastName"
                                            :state="errors.length > 0 ? false : null" size="md" value=""
                                            v-on:keypress="isLetter($event)" type="text" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.lastName">{{
                                            serverErrors.lastName[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="4">
                                <b-form-group labe-for="gender" label="Gender" style="cursor: pointer">
                                    <validation-provider #default="{ errors }" name="Gender" rules="">
                                        <b-form-select size="md" v-model="clerk.gender" id="gender" name="gender"
                                            :state="errors.length > 0 ? false : null">
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option value="female">Female</b-form-select-option>
                                            <b-form-select-option value="male">Male</b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.gender">{{
                                            serverErrors.gender[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="4">
                                <b-form-group labe-for="type" label="In Charge Of" style="cursor: pointer">
                                    <validation-provider #default="{ errors }" name="type">
                                        <b-form-select size="md" v-model="clerk.type" id="type" name="type"
                                            :state="errors.length > 0 ? false : null">
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option value="1">Buying</b-form-select-option>
                                            <b-form-select-option value="2">Dispatch</b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.type">{{
                                            serverErrors.type[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col> 

                            <!-- <b-col cols="4">
                                <b-form-group labe-for="location_id" label="Region" style="cursor: pointer">
                                    <validation-provider #default="{ errors }" name="region">
                                        <b-form-select size="md" v-model="clerk.location_id" id="location_id" name="location_id"
                                            :state="errors.length > 0 ? false : null">
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option v-for="location in locations" :key="location.id"
                                                :value="location.id">
                                                {{ location.name.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.location_id">{{
                                            serverErrors.location_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col> -->
                        </b-row>

                        <!-- <b-row>
                            <b-col cols="4">
                                <b-form-checkbox :checked="clerk.incharge" class="custom-control-success"
                                    name="check-button" switch v-model="clerk.incharge">
                                    <span class="switch-icon-left">
                                        <feather-icon icon="CheckCircleIcon" />
                                    </span>
                                    <span>Is Incharge</span>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="8" v-if="clerk.incharge == false">
                                <b-form-group labe-for="clerk_id" label="Incharge" style="cursor: pointer">
                                    <validation-provider #default="{ errors }" name="incharge" rules="">
                                        <b-form-select size="md" v-model="clerk.clerk_id" id="clerk_id" name="clerk_id"
                                            :state="errors.length > 0 ? false : null">
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option v-for="clerk in clerks" :key="clerk.id"
                                                :value="clerk.id">
                                                {{ clerk.person.firstName.toUpperCase() }}  {{ clerk.person.lastName.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.clerk_id">{{
                                            serverErrors.clerk_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row> -->

                    </b-form>
                    <!-- <pre>{{ clerk }}</pre> -->

                </validation-observer>
            </template>
        </b-modal>
        <!-- ./Form Modal -->

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."
                                size="md" />
                            <b-button variant="primary" size="md" @click="invokeClassifierForm()">
                                <span class="text-nowrap">Add Clerk</span>
                            </b-button>
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table striped hover small ref="records" class="position-relative" :items="fetch" responsive :fields="columns"
                primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No records found"
                :sort-desc.sync="isSortDirDesc">

                <template #cell(firstName)="data">
                    {{ data.item.person.firstName.toUpperCase() }}
                </template>

                <template #cell(middleName)="data">
                    <span v-if="data.item.person.middleName != null">{{ data.item.person.middleName.toUpperCase() }}</span>
                </template>

                <template #cell(lastName)="data">
                    {{ data.item.person.lastName.toUpperCase() }}
                </template>

                <template #cell(gender)="data">
                    {{ data.item.person.gender.toUpperCase() }}
                </template>

                <template #cell(type)="data">
                    <b-badge pill :variant="`light-${resolveTypeVariant(data.item.type)}`" class="text-capitalize">
                        <span v-if="data.item.type == 1">Buying</span>
                        <span v-else-if="data.item.type == 2">Dispatch</span>
                    </b-badge>
                </template>

                <!-- <template #cell(location)="data">
                    {{ data.item.location[0].name.toUpperCase() }}
                </template> -->

                <!-- <template #cell(incharge)="data">
                    <b-badge pill :variant="`light-${resolveTypeVariant(data.item.incharge)}`" class="text-capitalize">
                        <span v-if="data.item.incharge == true">Yes</span>
                        <span v-else-if="data.item.incharge == false">No</span>
                    </b-badge>
                </template> -->



                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="confirmDelete(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem,
    BSpinner, BFormCheckbox,BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import clerksStoreModule from '@/views/cromis/clerks/clerksStoreModule'
import useClerksList from '@/views/cromis/clerks/useClerksList'


export default {
    props: {},
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem,
        BSpinner,
        ValidationObserver, ValidationProvider, BFormCheckbox,BBadge,
    },
    directives: {},
    setup(props, context) {
        const dataForm = ref(null)
        const myModal = ref(null)
        const myModalDriver = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)
        const selections = ref([])
        const formSelections = ref([])
        const levels = ref(null)
        const children = ref([])
        const locations = ref([])
        const clerks = ref([])
        const selectedCompany = ref(null)

        const transporter = ref({
            id: null,
            company_id: null,
            tin: null,
            name: null,
            vrn: null,
            busines_licence: null,
        })

        const clerk = ref({
            id: null,
            code: null,
            firstName: null,
            lastName: null,
            middleName: null,
            gender: null,
            location_id: null,
            incharge:null,
            clerk_id:null
        })

        const CROMIS_STORE_MODULE_NAME = 'cromis-clerk'

        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, clerksStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        onMounted(async () => {

            await store.dispatch('cromis-clerk/locations')
                .then(response => {
                    locations.value = response.data.locations
                    console.log(locations.value)
                })
                .catch(error => {
                    console.log('got error', error)
                })

                await store.dispatch('cromis-clerk/list')
                .then(response => {
                    clerks.value = response.data.clerks
                    console.log(clerks.value)
                })
                .catch(error => {
                    console.log('got error', error)
                })

        })



        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Filers
            resolveTypeVariant,
            locationFilter,
        } = useClerksList()

        // Form Methods
        const invokeUpdateForm = (item) => {
            console.log(item)
            serverErrors.value = null

            clerk.value = {
                id: item.id,
                code: item.code,
                firstName: item.person.firstName,
                lastName: item.person.lastName,
                middleName: item.person.middleName,
                gender: item.person.gender,
                type: item.type,
                location_id: item.location[0].id,
                incharge: item.incharge,
                clerk_id: item.leader ? item.leader.id: null
            }

            myModalDriver.value.show()
        }

        const invokeClassifierForm = () => {
            serverErrors.value = null

            clerk.value = {
                id: null,
                code: null,
                firstName: null,
                lastName: null,
                middleName: null,
                gender: null,
                type: null,
                location_id: null,
                incharge:null,
                clerk_id:null
            }
            myModalDriver.value.show()
        }

        const invokeCreateForm = () => {
            formSelections.value.map((form) => {
                form.model = null
            })

            clerk.value = {
                id: null,
                code: null,
                firstName: null,
                lastName: null,
                middleName: null,
                gender: null,
                type: null,
                location_id: null,
                incharge:null,
                clerk_id:null
            }

            myModal.value.show()
        }

        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            // Handle form submit
            if (transporter.value.id === null || transporter.value.id === 0)
                handleCreate()
            else
                handleUpdate(sector.value)
        }

        const handleCreate = async () => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }



        }

        const isLetter = (e) => {
            let char = String.fromCharCode(e.keyCode);
            if (/^[A-Za-z"'"]+$/.test(char)) return true;
            else e.preventDefault();
        }

        const submitDriver = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null

            if (clerk.value.id === null || clerk.value.id === 0)
                handelDriverCreate()
            else
                handleUpdate(clerk.value)

        }


        const handleUpdate = async (item) => {
            saving.value = true
            const valid = await isFormValid()
            console.log(item)
            if (!valid) {
                saving.value = false
                return
            }

            await store.dispatch('cromis-clerk/update', { id: item.id, data: item })
                .then(response => {

                    console.log(response)
                    refetch()
                    saving.value = false

                    myModalDriver.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to Clerk has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    console.log(error)
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })

        }


        const handelDriverCreate = async () => {

            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }


            await store.dispatch('cromis-clerk/create', clerk.value)
                .then(response => {
                    refetch()
                    saving.value = false

                    context.root.$swal({
                        icon: 'success',
                        text: 'The Clerk has been added successfully!',
                        showConfirmButton: true,
                        timer: 5000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                    clerk.value = {
                        id: null,
                        license: null,
                        firstName: null,
                        lastName: null,
                        middleName: null,
                        gender: null,
                        location_id: null,
                        incharge: null,
                        clerk_id:null
                    }
                    myModalDriver.value.hide()
                })
                .catch(error => {
                    console.log(error)
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const confirmDelete = async (id) => {
            const result = context.root.$swal({
                icon: 'info',
                title: 'System Info',
                text: 'Are you sure you want to delete the Clerk?',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel',
                customClass: {
                    confirmButton: 'btn btn-info',
                    cancelButton: 'btn btn-danger ml-2', // Optional: Customize the cancel button class
                },
                buttonsStyling: true,
            }).then((result) => {

                if (result.value) {
                    remove(id)
                } else {
                    context.root.$swal({
                        icon: 'info',
                        title: 'Cancelled',
                        text: 'Clerk is safe you cancell deletion of it',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-info',
                        },
                        buttonsStyling: true,
                    })
                }
            })

        }


        const remove = async (id) => {
            await store.dispatch('cromis-clerk/remove', id)
                .then(response => {
                    refetch()
                })
                .catch(error => {
                    context.root.$swal({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Something went wrong. See tech support',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: true,
                    })
                })
        }

        return {
            // Data
            selections,
            formSelections,
            levels,
            children,
            transporter,
            clerk,
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,
            resolveTypeVariant,
            isLetter,
            // Methods
            required,
            saving,
            serverErrors,
            dataForm,
            myModal,
            myModalDriver,
            locationFilter,
            invokeCreateForm,
            invokeUpdateForm,
            submit,
            submitDriver,
            isFormValid,
            handleCreate,
            handleUpdate,
            confirmDelete,
            remove,
            locations,
            clerks,
            selectedCompany,
            invokeClassifierForm
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>